// Import necessary React and Material-UI components
import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import { CloudUpload } from '@material-ui/icons';
import appVars from "../../appVars.json"; // Import application variables (e.g., API URL)
import { lightenHexColor } from '../../dwi-functions';



// Define the FileUploads component
const FileUploads = (props) => {
    // Use the useStyles hook to apply the defined styles to the component

    // Use makeStyles hook from Material-UI to define component styles
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%', // Ensure the component takes the full width
        },
        button: {
            marginTop: theme.spacing(1), // Add spacing above the button
            marginBottom: theme.spacing(2), // Add spacing below the button
          //  background: props.themeColor.secondary,
           // color: props.themeColor.primary
        },
    }));

    const classes = useStyles();

    // Define state variables to manage the image preview URL, selected file, file picked status, and dialog open status
    const [imagePreviewUrl, setImagePreviewUrl] = useState(appVars.api_url + '/imageFiles/placeholder.png'); // Initial state uses placeholder image
    const [selectedFile, setSelectedFile] = useState(null); // State for the selected file
    const [isFilePicked, setIsFilePicked] = useState(false); // State to track if a file has been picked
    const [open, setOpen] = useState(false); // State to control the visibility of the upload dialog
    const [admintoken, setadmintoken] = useState((props.admintoken ? props.admintoken : 0));
    const theme = useTheme();

    console.log(props);
    // Define a handler for closing the dialog, which resets the component state to initial values
    const handleClose = () => {
        setOpen(false);
        setImagePreviewUrl(appVars.api_url + '/imageFiles/placeholder.png'); // Reset to placeholder image
    };

    // Define a handler for file selection changes, which updates the selected file and its preview URL
    const changeFileHandler = (event) => {
        const file = event.target.files[0]; // Get the selected file
        if (file) {
            setSelectedFile(file); // Update the selected file state
            setIsFilePicked(true); // Set file picked status to true
            const previewUrl = URL.createObjectURL(file); // Generate a preview URL for the file
            setImagePreviewUrl(previewUrl); // Update the preview URL state
        }
    };

    // Define a handler for uploading the selected file
    const handleUpload = async () => {
        const formData = new FormData(); // Create a FormData object to hold the file data
        formData.append('filesToUpload', selectedFile); // Append the selected file to the FormData object
        formData.append('pkRecID', props.newid); // Append the record ID from props to the FormData object

        // Attempt to upload the file using fetch API
        try {
            const response = await fetch(appVars.api_url + "/upload", {
                method: "POST",
                mode: "no-cors", // No-cors mode for cross-origin requests
                body: formData, // Set the request body to the FormData object
                redirect: 'follow' // Follow redirects after upload
            });
            const result = await response.text();
            handleClose(); // Close the dialog after successful upload
            props.childToParent(result); // Pass the result back to the parent component
        } catch (error) {
            console.error('Error uploading file:', error); // Log any errors to the console
        }
    };

    // Render the component
    return (
        <div className={classes.root}>
            {(admintoken.token >= 8 ?
                <div style={{textAlign:'center'}}>
                    <Typography align='center' variant="caption">Recommended Size/Type: {props.suggest}</Typography>
                     <Button className={classes.button} size="small" fullWidth variant="contained" component="label"  color="primary" onClick={() => setOpen(true)} startIcon={<CloudUpload />}>
                    Upload Image
                    <input hidden type="file" onChange={changeFileHandler} /> {/* Hidden file input for selecting files */}
                </Button>
                    
                </div>
                : '')}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={'md'}>
                <DialogTitle id="form-dialog-title" style={{ background: props.themeColor.primary, color: props.themeColor.secondary }}>Select image to upload</DialogTitle>
                <DialogContent style={{ background: lightenHexColor(props.themeColor.primary, 100), padding: '32px' }}>
                    {isFilePicked ? ( // Conditionally render based on if a file has been picked
                        <Grid container spacing={3} alignItems='center' justifyContent='center' >
                            <Grid item md={6}>
                                <div>
                                    {/* Display selected file information */}
                                    <p>Filename: {selectedFile?.name}</p>
                                    <p>Filetype: {selectedFile?.type}</p>
                                    <p>Size in bytes: {selectedFile?.size}</p>
                                    <p>LastModifiedDate: {selectedFile?.lastModifiedDate.toLocaleDateString()}</p>
                                </div>
                            </Grid>
                            <Grid item md={6} style={{ background: lightenHexColor(props.themeColor.secondary, 0), borderRadius: theme.borderRadius }}
                            >
                                {/* Display the image preview */}
                                <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                            </Grid>
                        </Grid>
                    ) : (
                        <p>Select a file to show details</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' style={{ background: lightenHexColor(props.themeColor.secondary, 0), color: props.themeColor.primary }}>Cancel</Button>
                    <Button onClick={handleUpload} style={{ background: lightenHexColor(props.themeColor.primary, 0), color: props.themeColor.secondary }} variant="contained">Upload</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FileUploads; // Export the component for use in other parts of the app
