import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ClientSearch from "../../controls/client-search";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Image, Settings, ViewAgendaOutlined } from '@material-ui/icons'
import MoreIcon from '@material-ui/icons/MoreVert';
import { Skeleton } from "@material-ui/lab";
import { useMediaQuery, Avatar, Tabs, Tab, Snackbar } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { setCookie, getCookie, lightenHexColor, darkenHexColor } from '../../../dwi-functions';
import appVars from '../../../appVars.json';
import { useLocation } from 'react-router-dom';
import ClipboardJS from 'clipboard';
import PlatformSearch from "../../controls/platform-search";
import { BsMeta, BsFiletypeHtml } from "react-icons/bs";
import { SlNotebook } from "react-icons/sl";
import { FaRegIdCard, FaTags } from 'react-icons/fa';
import { RiPictureInPictureExitLine, RiErrorWarningLine } from "react-icons/ri";
import { FcHighPriority } from "react-icons/fc";
const drawerWidth = 200;




export default function MiniDrawer(props) {
  // eslint-disable-next-line

  //console.log(props);

  const location = useLocation();
  var id = location.pathname.split("/")[2];
  const searchParams = new URLSearchParams(location.search);
  const tab = (searchParams.get('tab') ? Number(searchParams.get('tab')) : 0)
  const [snack, setSnack] = useState({ msg: "", open: false });

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    appBar: {
      background: theme.palette.appBar.background,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(6) + 1,
      },
    },
    avatar: { float: 'right' },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      height: '92px',
      background: lightenHexColor(theme.palette.primary.main, 0),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    grow: {
      flexGrow: 1,
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,

      marginRight: theme.spacing(1),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
      [theme.breakpoints.only('xs')]: {
        display: 'none',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    siteTitle: {
      width: "100%",

    },
    logoContain: {
      //maxWidth: "150px",
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center'
    },
    logos: {
      width: "auto",
      textAlign: 'center',
      background: '#f7f7f7',
      padding: theme.spacing(.5),
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(.25),
    },
    navLogo: {
      backgroundColor: theme.palette.secondary.main,
    },
    selected: {
      // Define your active tab color here
      background: darkenHexColor(props.brandColor.primary, 0),
      color: "#fff",
    },
    tab: {
      //fontSize: '18px',
      justifyContent: 'flex-start',
      flexDirection: 'row', // Aligns the icon and text horizontally
      '& > *:first-child': {
        marginRight: theme.spacing(3), // Adds spacing between the icon and text
        fontSize: '20px',
        marginBottom: '0px!important',
        minWidth: '20px'
      },
    }
  }));

  const classes = useStyles();
  const admintoken = props.admintoken.token;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logOut = () => {
    setCookie('_au', '', -1);
    setCookie('_auta', '', -1);
    setCookie('_auta_s', '', -1);
    sessionStorage.removeItem("_auta_s");
    sessionStorage.removeItem("_au");
    sessionStorage.removeItem("_auta");
    window.location.href = "/";
  }


  var clipboard = new ClipboardJS('#urlCopy');

  clipboard.on('success', function (e) {
    setSnack({ msg: e.text + "  Copied!", open: true });
    e.clearSelection();
  });

  clipboard.on('error', function (e) {
    setSnack({ msg: e.action + "  Error!", open: true });
  });




  const theme = createTheme({
    palette: {
      primary: {
        main: darkenHexColor(props.brandColor.primary, 0), // Assuming darkenHexColor returns a valid color
      },
      secondary: {
        main: darkenHexColor(props.brandColor.secondary, 0), // Assuming the same
      },
    },
    overrides: {
      MuiAppBar: {
        root: {
          height: '92px', // Set the height of the AppBar
          background: 'green'
        }
      }
    },
    MuiToolbar: {
      root: {
        minHeight: '92px',  // Set custom height for the Toolbar
        '@media (min-width:0px) and (orientation: landscape)': {
          minHeight: '70px'  // Set custom height for landscape mode
        },
        '@media (min-width:600px)': {
          minHeight: '92px'  // Set custom height for devices above 600px wide
        }
      }
    },
    mixins: {
      toolbar: {
        minHeight: 92,  // Align the mixin minHeight with the Toolbar custom height
        '@media (min-width:0px) and (orientation: landscape)': {
          minHeight: '70px'  // Custom height for landscape orientation
        },
        '@media (min-width:600px)': {
          minHeight: '92px'  // Custom height for wider screens
        }
      }
    }
  });
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  // console.log('desktop:', isDesktop);

  const [open, setOpen] = React.useState(true);
  const [value, setValue] = useState(tab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlesnackClose = (e) => {
    setSnack({ msg: "", open: false });
  }








  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };



  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { window.location = "/account/" + getCookie('_au'); }}>My account</MenuItem>
      {(admintoken >= 10 ? <MenuItem onClick={() => { window.location = "/control"; }}>Settings</MenuItem> : <Skeleton variant="rect" style={{ padding: theme.spacing(1) }} >Admin Access Needed</Skeleton>
      )}
      <MenuItem onClick={logOut}>Logout</MenuItem>


    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

    </Menu>
  );




  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    return () => {

    };
    // eslint-disable-next-line
  }, props);



  return (
    <div className={classes.root}>

      <CssBaseline />

      <AppBar
        variant="outlined"
        position="fixed"
        color="primary"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>

          <div className={classes.logoContain1}>
            <img
              style={{ maxHeight: 85 }}
              className={classes.logos}
              alt=''
              src={appVars.api_url + '/imageFiles/' + id + '.png'}
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop if fallback image also fails
                e.target.src = 'https://place-hold.it/300x100/aaa/000000/b8b8b8.jpg&text=NO-LOGO'; // Set your fallback image source here
              }}
            />

          </div>
          <div className={classes.grow} />
          <div className={classes.search}>

          </div>
          <div className={classes.search}>
            <PlatformSearch sitetype='donation' title="Search Donation Platforms"></PlatformSearch>
          </div>
          <div className={classes.search}>
            <ClientSearch

              onChange={(e, ne) => {
                window.location = '/subclientviewer/' + ne.id;
                //console.log(ne);
              }}

            ></ClientSearch>
          </div>


          <div>
            <div className={classes.grow} />

            <div className={classes.sectionDesktop}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar variant="circular" src={appVars.api_url + '/imageFiles/' + getCookie("_au") + '.png'} />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </div>


        </Toolbar>

      </AppBar>
      {renderMobileMenu}
      {renderMenu}


      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div className={classes.logoContain}>

          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />


        <div style={{ justifyContent: 'flex-start' }}>

          <Tabs indicatorColor="secondary" style={{ width: '100%' }} className={classes.tabs} orientation='vertical' variant="standard" scrollButtons="auto" value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab icon={<ViewAgendaOutlined  ></ViewAgendaOutlined>} label="Overview" onClick={(e) => { e.preventDefault(); props.setValue(0); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} />
            <Tab icon={<Settings></Settings>} label="Settings" onClick={(e) => { e.preventDefault(); props.setValue(1); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} />
            {(admintoken >= 10 ? <Tab icon={<FaTags></FaTags>} label="Tracking Tags" onClick={(e) => { e.preventDefault(); props.setValue(2); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} /> : '')}
            {(admintoken >= 10 ? <Tab icon={<BsMeta></BsMeta>} label="Meta Trackers" onClick={(e) => { e.preventDefault(); props.setValue(3); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} /> : '')}
            {(admintoken >= 10 ? <Tab icon={<FaRegIdCard></FaRegIdCard>} label="Conversions" onClick={(e) => { e.preventDefault(); props.setValue(4); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} /> : '')}
            {(admintoken >= 6 ? <Tab icon={<RiPictureInPictureExitLine></RiPictureInPictureExitLine>} label="Lightboxes" onClick={(e) => { e.preventDefault(); props.setValue(5); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} /> : '')}
            {(admintoken >= 10 ? <Tab icon={<BsFiletypeHtml></BsFiletypeHtml>} label="Landing Pages" onClick={(e) => { e.preventDefault(); props.setValue(6); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} /> : '')}
            {(admintoken >= 10 ? <Tab icon={<Image></Image>} label="Images" onClick={(e) => { e.preventDefault(); props.setValue(7); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} /> : '')}
            {(admintoken >= 10 ? <Tab icon={<FaTags></FaTags>} label="Reports" onClick={(e) => { e.preventDefault(); props.setValue(8); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} /> : '')}
            {(admintoken >= 10 ? <Tab icon={<FcHighPriority />} label="Elements" onClick={(e) => { e.preventDefault(); props.setValue(9); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} /> : '')}
            {(admintoken >= 6 ? <Tab icon={<RiErrorWarningLine />} label="API Errors" onClick={(e) => { e.preventDefault(); props.setValue(10); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} /> : '')}
            {(admintoken >= 6 ? <Tab icon={<SlNotebook />} label="Notes" onClick={(e) => { e.preventDefault(); props.setValue(11); }} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} /> : '')}
          </Tabs>
        </div>




      </Drawer>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handlesnackClose}
          open={snack.open}
          color="success"
          autoHideDuration={3000}
          message={snack.msg}
        />
      </div>

    </div>
  );
}
