import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Paper, Box, Link, makeStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, ButtonGroup, createTheme, ThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import dwLogo from '../../logo.png';
import { useLocation } from 'react-router-dom';
import appVars from '../../appVars.json';
import VerticalSearch from '../controls/vertical-search';
import UserSearch from '../controls/user-search';
import { generatePDF } from "../../dwi-functions";
import _ from "lodash";
import { FcPrint } from 'react-icons/fc';
import ErrorView from '../../ErrorView';



// Custom styles
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%', // Make it responsive
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
        },
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
        margin: 'auto', // Center the container

    },
    companyInfo: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
    },
    tableContainer: {
        marginTop: theme.spacing(2),
    },
    logo: {
        width: '100%', // Make it responsive
        [theme.breakpoints.down('sm')]: {
            width: 400,
            margin: 'auto'
        },
        height: 'auto', // Maintain aspect ratio

    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    screenshot: {
        width: '100%', // Make it responsive
        [theme.breakpoints.down('sm')]: {
            maxWidth: 400,
        },
        height: 'auto',
    },
    footerImages: {
        width: '100%', // Make it responsive
        [theme.breakpoints.up('sm')]: {
            maxWidth: 150,
        },
        height: 'auto',
    },
    footer: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },

        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        borderTop: `2px solid ${theme.palette.divider}`,

    },
    copyright: {
        textAlign: 'center',
        flexGrow: 1,
        fontSize: '0.875rem', // 14px in rem
    }
}));

const Invoice = (props) => {
    const classes = useStyles();
    const location = useLocation();
    var id = location.pathname.split("/")[2];


    const theme = createTheme({
        typography: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',  // Default Material-UI font
            h1: {
                fontFamily: '"Montserrat", sans-serif',  // Custom font for h1
            },
            body1: {
                fontFamily: '"Open Sans", sans-serif',   // Custom font for body text
            },
        },
        palette: {
            primary: {
                main: '#399cdb',
            },
            secondary: {
                main: '#e3e3e3'
            }
        },
        overrides: {
            MuiDialogTitle: {
                root: {
                    background: "linear-gradient(270deg, rgba(79,195,247,1) 0%, rgba(65,133,244,1) 100%)",
                    color: "#ffffff"
                }
            },
            MuiToolbar: {
                root: {
                    background: "linear-gradient(270deg, rgba(79,195,247,1) 0%, rgba(65,133,244,1) 100%)",
                    color: "#ffffff"
                }
            }

        },
        props: {
            MuiTable: {
                size: "small",
                variant: "outlined"
            },
            MuiPaper: {
                variant: "outlined"
            }
        }
    });
    const [client, setClient] = useState();
    const [tags, setTags] = useState([]);
    const [openErrors, setopenErrors] = useState();
    const printref = useRef();

    const handleClose = () => {
        setopenErrors(false);
    };




    // Columns for the MaterialTable
    const columns = [
        {
            title: 'ID', field: 'settings', render: (rowData) => {
                return (
                    <img
                        style={{ maxWidth: 75 }}
                        src={appVars.api_url + '/imageFiles/' + rowData.tagProfile + '.png'}
                        alt=''
                    />
                )
            }
        },
        { title: 'Name', field: 'type' },
        {
            title: 'ID', field: 'settings', render: (rowData) => {
                var a = JSON.parse(rowData.settings);

                return (
                    <Typography variant="body2">{a.id}</Typography>
                )
            }
        },
        {
            title: 'Label/ConversionID', field: 'label', render: (rowData) => {
                var a = JSON.parse(rowData.settings);

                return (
                    <Typography variant="body2">{a.conversion}</Typography>
                )
            }
        }
    ];


    async function fetchData() {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const res = await fetch(appVars.api_url + "/tagmanager/" + id);
        res
            .json()
            .then(res => {
                res.pkRecId = id;
                setClient(res);
                document.title = res.clientname + ": " + res.name;

            });

        const res2 = await fetch(appVars.api_url + "/tags/" + id, requestOptions);
        res2.json()
            .then((response) => {
                var k = _.sortBy(response, 'type');
                setTags(k);
                // console.log(k);
            })
            .catch(error => console.log('error', error));

    }

    useEffect(() => {
        fetchData();
        return () => {
            //  console.log(client);
            //  console.log(tags)
        };
    }, []);



    // Get current year for footer
    const currentYear = new Date().getFullYear();

    return (
        <ThemeProvider theme={theme}>
            <Paper elevation={props.elevation} variant="elevation" style={{ maxWidth: props.widthS }} className={classes.root}>
                {(props.toolbar!==false ?
                    <Grid container style={{ maxWidth: props.widthS, margin: 'auto', marginBottom: theme.spacing(2) }}>
                        <Grid item md={12} >
                            <Box pt={1} pb={0} m={0} >
                                <ButtonGroup size="small">
                                    <Button startIcon={<FcPrint />} variant="contained" color="primary" title='Print Report'
                                        onClick={() => generatePDF(printref, '', '')}
                                    >Print
                                    </Button>
                                    <Button variant="contained" color="primary" title='Print Report'
                                        onClick={() => generatePDF(printref, '', '')}
                                    >GTM Install Instructions
                                    </Button>
                                    <Button variant="contained" color="primary" title='Print Report'
                                        onClick={() => generatePDF(printref, '', '')}
                                    >META Domain Verification
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Grid>
                    </Grid>
                    : '')}
                {(client ?
                    <div ref={printref} >
                        <Grid container spacing={3}>
                            {/* Row 1 - Company Info, Screenshot, Logo */}
                            <Grid item xs={12} md={10}>
                                <Box className={classes.companyInfo}>
                                    <Typography variant="h5" gutterBottom>{client.name}</Typography>
                                    <Typography variant="body1">
                                        Agency: <Link href={client.agency} target="_blank">{client.clientname}</Link>
                                    </Typography>

                                    <Typography variant="body1">
                                        Website: <Link href={client.siteUrl} target="_blank">{client.siteUrl}</Link>
                                    </Typography>
                                    <Typography variant="body1">
                                        eCommerce: <Link href={client.donationUrl} target="_blank">{client.donationUrl}</Link>
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <img
                                    crossOrigin="use-credentials"
                                    src={appVars.api_url + '/imageFiles/' + client.id + '.jpeg'} // Update with your screenshot URL or path
                                    alt="Company Logo"
                                    className={classes.logo}
                                />
                            </Grid>

                            {/* Row 2 - Vertical, Agency, Avatar + Email Links */}
                            <Grid item xs={12} md={2}>
                                <VerticalSearch label="Vertical" fullWidth disabled={true} value={client.vertical} />
                            </Grid>
                            <Grid item xs={12} md={3}>

                                <UserSearch label="Owner" size="small" fullWidth value={client.owner} disabled={true} />
                            </Grid>
                            <Grid item xs={12} md={3}>

                                <UserSearch label="Support" size="small" fullWidth value={client.support} disabled={true} />
                            </Grid>

                            {/* Row 3 - Website Thumbnail and Ecommerce System Logo */}
                            <Grid alignContent='center' item xs={12} md={2}>
                                <img
                                    crossOrigin="use-credentials"
                                    src={appVars.api_url + '/imageFiles/' + client.sitetype + '.png'} // Update with your website thumbnail path
                                    alt="Website Thumbnail"
                                    className={classes.screenshot}
                                />
                            </Grid>
                            <Grid alignContent='center' item xs={12} md={2}>
                                <img
                                    crossOrigin="use-credentials"
                                    src={appVars.api_url + '/imageFiles/' + client.donationtype + '.png'} // Update with your ecommerce system logo path
                                    alt="Ecommerce System Logo"
                                    className={classes.screenshot}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction="row-reverse" >
                            {/* Row 4 - Full Width Table */}
                            <Grid item sm={3} xs={12}>
                                <Paper>
                                    <Box p={2}>
                                        <Typography variant="body2">Last Track: {'Add time'}</Typography>
                                        <Typography variant="body2">Errors:  <Link onClick={() => setopenErrors(openErrors => !openErrors)} >0</Link></Typography>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item sm={9} xs={12}>
                                <MaterialTable
                                    title="Tracking Tags"
                                    columns={columns}
                                    data={tags}
                                    options={{
                                        search: true,
                                        paging: false,
                                        sorting: true,
                                        showTitle: true,
                                        cellStyle: (rowData, index) => ({
                                            padding: 2,

                                        }),
                                        rowStyle: (rowData, index) => ({
                                            backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
                                        }),
                                    }}
                                />
                            </Grid>

                        </Grid>




                        <Box className={classes.footer}>
                            <Box bgcolor={"#e3e3e3"} borderRadius={2} p={1} >
                                <img
                                    crossOrigin="use-credentials"
                                    src={appVars.api_url + '/imageFiles/' + client.id + '.png'} // Update with your screenshot URL or path
                                    alt={"Logo | " + client.name}
                                    className={classes.footerImages}
                                />
                            </Box>
                            <Typography className={classes.copyright}>
                                &copy; {currentYear} DigitalWerks Innovations.
                            </Typography>

                            <img
                                crossOrigin="use-credentials"
                                style={{ maxWidth: 150 }}
                                src={dwLogo} // Update with your logo URL or path
                                alt="Logo | DigitalWerks Innovations"
                                className={classes.footerImages}
                            />
                        </Box>
                        <Dialog fullWidth maxWidth="md" open={openErrors} onClose={handleClose}>
                            <DialogTitle >{"Errors"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <ErrorView clientid={client.id}></ErrorView>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>

                                <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>


                    : '')}

            </Paper>





        </ThemeProvider>
    );
};

export default Invoice;
