import React, { useEffect } from 'react';
import { Box, CardActions, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import appVars from '../../appVars.json';
import Card from '@material-ui/core/Card';
import { CardActionArea, CardContent, CardHeader, CardMedia, Link, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { lightenHexColor } from '../../dwi-functions';
var CurrencyFormat = require('react-currency-format');

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    content: {
        padding: theme.spacing(0),

    },
    media: {
        height: 'auto',
        maxWidth: 100,
        fontSize: 12,
        padding: theme.spacing(0),
        //filter: 'brightness(0) invert(1)'
    },
    pad: {
        padding: 1
    },
    logoArea: {
        background: lightenHexColor(theme.palette.primary.light, 30),
        maxHeight: 75
    }
}));

const PrimaryClient = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [clients, setClients] = useState();
    const [isLoading, setisLoading] = useState(true);
    const [status, setStatus] = useState();


    async function fetchData() {

        fetch(appVars.api_url + '/clients')
            .then((response) => response.json())
            .then((res) => {
                setClients(res);
                //    console.log(res);
                setisLoading(false);
            }).catch((err) => console.log(err))




        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        const r = await fetch(appVars.api_url + "/status", requestOptions);
        r.json()
            .then((response) => setStatus(response))
            .then((result) => console.log(result))
            .catch((error) => console.error(error));




    }




    useEffect(() => {
        fetchData();
        console.log(status)
    }, []);



    return (

        <div className={classes.content}>
            <Grid container spacing={2} direction="row-reverse" >

                <Grid item xl={2} md={3} xs={12} style={{ marginTop: theme.spacing(1), paddingLeft: 7, paddingRight: 7, borderLeft: "1px solid #e4e4e4", height: "calc(100vh - 70px)" }}>
                <Typography variant="h5" style={{ borderBottom: '1px solid' }} gutterBottom>Engine Status</Typography>
                    {(status ?
                        <Box>
                            
                            <Typography variant="body2" color="textPrimary" gutterBottom>Status: {status.status}...</Typography>
                            <Typography variant="body2" color="textPrimary" gutterBottom>Version: {status.version}</Typography>
                            <Typography variant="body2" color="textPrimary" gutterBottom>Secure: {status.use_ssl}</Typography>
                            <Typography variant="body2" color="textPrimary" gutterBottom>RunID: {status.run_id}</Typography>
                        </Box>
                        : '')}
                </Grid>


                <Grid item xl={10}>
                    <Grid container spacing={1} direction="row-reverse" >

                        {(clients ?
                            Object.keys(clients).map((k, i, all_items) => {
                                return (

                                    <Grid xl={3} md={6} xs={12} item>
                                        <Card variant="outlined" className={classes.root}>
                                            <CardActionArea href={'/clientview/' + clients[k].clientId}>
                                                <CardHeader
                                                    className={classes.logoArea}
                                                    title={<img className={classes.media} src={clients[k].logo} alt={clients[k].clientname} ></img>}

                                                />
                                            </CardActionArea>
                                            <CardContent style={{ padding: theme.spacing(1), minHeight: 125 }}>
                                                <Grid container xl={12}>
                                                    <Grid item xl={12}>
                                                        <Typography variant='body1'><Link href={'/clientview/' + clients[k].clientId} >{clients[k].clientname}</Link></Typography>
                                                        <Typography variant='body2'>{clients[k].addess}</Typography>
                                                        <Typography variant='body2'>{clients[k].city}, {clients[k].state} {clients[k].zip}</Typography>
                                                        <Typography variant='body2'>{clients[k].phone}</Typography>
                                                        <Typography variant="body2"><Link href={'/clientview/' + clients[k].email} >{clients[k].email}</Link></Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            <CardActionArea>
                                                <CardActions>
                                                    <Box borderColor={"#d9d9d9"} border={1} borderRadius={7} bgcolor={"#d4d4d4"} p={1}>
                                                        <Typography align='center' variant="body2">Active</Typography>
                                                        <Typography align='center'><Link color="primary" href={'/clientview/' + clients[k].clientId}>{clients[k].clientCount}</Link></Typography>
                                                    </Box>
                                                    <Box borderColor={"#d9d9d9"} border={1} borderRadius={7} bgcolor={"#d4d4d4"} p={1}>
                                                        <Typography align='center' variant="body2">Inacive</Typography>
                                                        <Typography align='center'>{clients[k].inactiveCount}</Typography>
                                                    </Box>
                                                    <Box borderColor={"#d9d9d9"} border={1} borderRadius={7} bgcolor={"#d4d4d4"} p={1}>
                                                        <Typography align='center' variant="body2">Convs</Typography>
                                                        <Typography align='center'>{clients[k].conversions}</Typography>
                                                    </Box>
                                                    <Box borderColor={"#d9d9d9"} border={1} borderRadius={7} bgcolor={"#d4d4d4"} p={1}>
                                                        <Typography align='center' variant="body2">Revenue</Typography>
                                                        <Typography align='center'><CurrencyFormat prefix="$" fixedDecimalScale={true} decimalScale={2} displayType="text" value={clients[k].revenue} ></CurrencyFormat></Typography>
                                                    </Box>

                                                </CardActions>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            })
                            :
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((card, index) => (
                                <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={index}>
                                    <Card>
                                        <CardHeader
                                            title={<Skeleton animation="wave" width="40%" component="h5" style={{ marginBottom: 6 }} />}
                                        >

                                        </CardHeader>
                                        <CardMedia>
                                            <Skeleton animation="wave" variant="rect" />
                                        </CardMedia>
                                        <CardContent>
                                            <Skeleton animation="wave" component="h1" width="65%" style={{ marginBottom: 6 }} />
                                            <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                            <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))

                        )


                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>

    );
};

PrimaryClient.propTypes = {

};

export default PrimaryClient;
